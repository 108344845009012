// extracted by mini-css-extract-plugin
export var takeOver__close = "takeOverBenfica-module--takeOver__close--4PDjT";
export var takeOver__columnCta = "takeOverBenfica-module--takeOver__columnCta--muNkl";
export var takeOver__columnJerseyCta = "takeOverBenfica-module--takeOver__columnJerseyCta--Mc+Wz";
export var takeOver__columnTextCta = "takeOverBenfica-module--takeOver__columnTextCta--8fYtP";
export var takeOver__container = "takeOverBenfica-module--takeOver__container--LIJRI";
export var takeOver__content = "takeOverBenfica-module--takeOver__content--LqYJN";
export var takeOver__divider = "takeOverBenfica-module--takeOver__divider--esXU8";
export var takeOver__left = "takeOverBenfica-module--takeOver__left--tuPgu";
export var takeOver__logo = "takeOverBenfica-module--takeOver__logo--6djlG";
export var takeOver__logos = "takeOverBenfica-module--takeOver__logos--RGzIo";
export var takeOver__offsetContainer = "takeOverBenfica-module--takeOver__offsetContainer---ahH1";
export var takeOver__offsetImage = "takeOverBenfica-module--takeOver__offsetImage--UEZji";
export var takeOver__right = "takeOverBenfica-module--takeOver__right--uQTOG";
export var takeOver__rightContent = "takeOverBenfica-module--takeOver__rightContent--xpjBH";
export var takeOver__showDesktop = "takeOverBenfica-module--takeOver__showDesktop--NczVX";
export var takeOver__showMobile = "takeOverBenfica-module--takeOver__showMobile--CtT3J";
export var takeOver__wCta = "takeOverBenfica-module--takeOver__wCta--o85Jc";
export var takeOver__wrapper = "takeOverBenfica-module--takeOver__wrapper--6ozsc";
export var takeOver__yCta = "takeOverBenfica-module--takeOver__yCta--q85CN";
export var takeoverNoScroll = "takeOverBenfica-module--takeoverNoScroll--pL3xi";