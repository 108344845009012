import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
// import { StaticImage } from 'gatsby-plugin-image';

import * as style from 'styles/components/utils/takeOverBenfica.module.scss';

import leftimage from '../../images/takeover/hammarby-stpolten-bg.jpg';

const TakeOver = ({ takeoverConfig }) => {

  const link = takeoverConfig.youtubeVideoId ? takeoverConfig.youtubeVideoId : null;

  const [isTakeOverActive, toggleTakeOver] = useState(true);

  function fakeNavigation() {
    toggleTakeOver(false);
    document.getElementById("seasonalTakeover").remove();
  }

  return (
    <div id="seasonalTakeover" className={style.takeOver__wrapper}>
      {isTakeOverActive &&
        <Helmet>
            <body data-modal-open="true" />
        </Helmet>
      }
      <div className={style.takeOver__container}>
        <div className={style.takeOver__content}>
          <button type="button" className={style.takeOver__close} aria-label='Stäng' data-testid="close-button" onClick={() => fakeNavigation(false)}>
            <svg viewBox="0 0 36 36" data-testid="close-icon" fill="#000000" width="32" height="32"><path d="M28.5 9.62L26.38 7.5 18 15.88 9.62 7.5 7.5 9.62 15.88 18 7.5 26.38l2.12 2.12L18 20.12l8.38 8.38 2.12-2.12L20.12 18z"/></svg>
          </button>
          <div className={style.takeOver__left}>
            <img src={leftimage} alt="Hammarby - Benfica" />
              <div className={style.takeOver__columnJerseyCta}>
              {link && (
                <a className={`${style.takeOver__columnCta} ${style.takeOver__wCta} ${style.takeOver__showDesktop}`} target="_blank" rel="noreferrer" href={link}>UWCL-paket</a>
              )}
              </div>
          </div>
          <div className={style.takeOver__right}>
            <div className={style.takeOver__rightContent}>
              <div className={style.takeOver__logos}>
                <div className={style.takeOver__logo}>
                  <img src="https://cdn.sanity.io/images/8k2hu6rb/production/484dfc0aec49ced4b1cfcf95d3ce77b0dc7515c6-275x281.png?rect=0,0,275,280" alt="Hammarby Fotboll" />
                </div>
                <div className={style.takeOver__divider}>
                –
                </div>
                <div className={style.takeOver__logo}>
                  <img src="https://cdn.sanity.io/images/8k2hu6rb/production/1fe3545ec64b44df59f2ffeabb076390e449923d-240x240.png?w=200&h=200&q=60&auto=format&dpr=1" alt="St. Pölten" />
                </div>
              </div>
              <h3>UEFA Women&apos;s Champions League</h3>
              <h2>Hammarby – St. Pölten</h2>
              <p>
              Under onsdagskvällen skrivs det grönvit historia när Alice leder ut laget i klubbens första Champions League-gruppspel. Var med och fyll Tele2 Arenas läktare. Det här vill du inte missa.
              </p>
              <div className={style.takeOver__columnTextCta}>
                <a className={`${style.takeOver__columnCta} ${style.takeOver__yCta}`} target="_blank" rel="noreferrer" href="https://hammarbyherr.ebiljett.nu/Tickets/Choose/561?S=0">Köp biljett</a>
                {link && (

                <a className={`${style.takeOver__columnCta} ${style.takeOver__wCta}  ${style.takeOver__wCta} ${style.takeOver__showMobile}`} target="_blank" rel="noreferrer" href={link}>UWCL-paket</a>
                )}
              </div>

            </div>
          </div>
        </div>
      </div>

    </div>
  );
};
export default TakeOver;
